import bidenFace from "../assets/characters/face/Biden.png";
import kamalaFace from "../assets/characters/face/Kamala.png";
import muskFace from "../assets/characters/face/Musk.png";
import obamaFace from "../assets/characters/face/Obama.png";
import trumpFace from "../assets/characters/face/Trump.png";
import tristanFace from "../assets/characters/face/George.png";

import bidenBody from "../assets/characters/body/Biden.png";
import kamalaBody from "../assets/characters/body/Kamala.png";
import muskBody from "../assets/characters/body/Musk.png";
import obamaBody from "../assets/characters/body/Obama.png";
import trumpBody from "../assets/characters/body/Trump.png";
import washingtonBody from "../assets/characters/body/Washington.png";

import { Character } from "../state/character";

export const CHARACTERS: Character[] = [
  {
    name: "BIDEN",
    faceImg: bidenFace,
    bodyImg: bidenBody,
    winningImg: "https://i.ibb.co/9cfhRPw/lOGO.jpg",
  },
  {
    name: "KAMALA",
    faceImg: kamalaFace,
    bodyImg: kamalaBody,
    winningImg: "https://i.ibb.co/9cfhRPw/lOGO.jpg",
  },
  {
    name: "MUSK",
    faceImg: muskFace,
    bodyImg: muskBody,
    winningImg: "https://i.ibb.co/9cfhRPw/lOGO.jpg",
  },
  {
    name: "OBAMA",
    faceImg: obamaFace,
    bodyImg: obamaBody,
    winningImg: "https://i.ibb.co/9cfhRPw/lOGO.jpg",
  },

  {
    name: "TRUMP",
    faceImg: trumpFace,
    bodyImg: trumpBody,
    winningImg: "https://i.ibb.co/9cfhRPw/lOGO.jpg",
  },
  {
    name: "GEORGE",
    faceImg: tristanFace,
    bodyImg: washingtonBody,
    winningImg: "https://i.ibb.co/9cfhRPw/lOGO.jpg",
  },
];
