import { Character } from "../state/character";
import styles from "../styles/share.module.css";
import pumpLogo from "../assets/PumpLogo.webp";
import { useGame } from "../game/engine/useGameEngine";
import { PUMP_LINK } from "../constants/social";

export default function ShareModal({ winner }: { winner: Character }) {
  const { replay } = useGame();

  // &url=${encodeURIComponent(winner.winningImg || window.location.href)}

  const handleShare = () => {
    // Open Twitter share window with dataURL
    const twitterShareUrl = `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      `I just won the $DOGB World Championship with ${winner.name}!\n\nBuy $DOGB NOW\n${window.location.href}\n@DeptOfGovBoxing\n\n`
    )}`;
    window.open(twitterShareUrl, "_blank");
  };

  return (
    <div className={styles.container}>
      <div className={styles.contentWrapper}>
        <div className={styles.header}>CHAMPION OF THE WORLD</div>
        <div className={styles.canvas} id="shareCanvas">
          <img src={winner.winningImg} alt="winning" />
        </div>
        <div className={styles.header}>SHARE TO X NOW!</div>

        <button onClick={handleShare} className={`button ${styles.shareBtn}`}>
          SHARE
        </button>

        <button
          className={`button ${styles.pumpBtn}`}
          onClick={() => window.open(PUMP_LINK, "_blank")}
        >
          <img src={pumpLogo} alt="pump logo" />
        </button>
        <button className={`button ${styles.replay}`} onClick={replay}>
          Replay
        </button>
      </div>
    </div>
  );
}
